@use '~@amzn/awsui-design-tokens/polaris.scss' as awsui;

#b {
  // accommodate the space for the fixed header
  padding-top: 38px;
}

#h {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  // corresponds to the z-index in Mezzanine
  z-index: 1000;
  margin: 0;
  padding: 0 30px;
  background: awsui.$color-background-home-header;
  font-family: "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
}

// temporary fix for this issue: https://issues.amazon.com/issues/AWSUI-10091
// it is needed for 2.1 components and should be removed after we migrate Modal and TablePreferences
.awsui-modal-open .awsui {
  z-index: 2000;
}

// needs to be more specific than `.awsui ul` style
.awsui ul.menu-list {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;

  & > li {
    padding: 0 11px;
    margin: 9px 0 9px;
    color: white;

    a,
    div,
    button {
      float: left;
      color: awsui.$color-foreground-control-default;
      line-height: 16px;
    }

    a,
    a:hover {
      cursor: pointer;
      text-decoration: none;
    }

    .text {
      margin-top: 5px;
    }

    &.title {
      font-weight: bold;
      font-size: 25px;
    }

    &.selected > a,
    &.selected > button {
      font-weight: bold;
      color: awsui.$color-text-accent;
    }

    &.external-link {
      border-left: 1px solid awsui.$color-border-control-default;
      padding-left: 9px;
      margin-left: 9px;
    }

    &.ml-auto {
      margin-left: auto;
    }
  }


  button,
  button:focus {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0 6px;
  }
}

.awsui-polaris-dark-mode {
  .awsui-app-layout {
    h2,
    h3,
    .awsui-util-container-header {
      color: awsui.$color-text-heading-default;
    }
  }
}
.card-header {
    top: 30%;
    height: 1px;
}

.card-divider {
    margin-left: -5px;
    margin-right: -5px;
}

.blue {
    border: 3px solid #1e88e5;
}

.orange {
    border: 3px solid #f57c00;
}  

.red {
    border: 3px solid #e53935;
}  

.purple {
    border: 3px solid #8e24aa;
}  

.silver {
    border: 3px solid #C0C0C0;
}  

.gold {
    border: 3px solid #FFD700;
}  

.circular-portrait {
    object-fit: cover;
    overflow: hidden;
    border-radius: 50%;
}

.small {
    width: 40px;
    height: 40px;
}

.large {
    width: 60px;
    height: 60px;
}